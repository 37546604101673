import { render, staticRenderFns } from "./Bills.vue?vue&type=template&id=905c2fba"
import script from "./Bills.vue?vue&type=script&lang=js"
export * from "./Bills.vue?vue&type=script&lang=js"
import style0 from "./Bills.vue?vue&type=style&index=0&id=905c2fba&prod&lang=scss"
import style1 from "./Bills.vue?vue&type=style&index=1&id=905c2fba&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports