<template>
	<div
		:class="$style.noAccess"
		v-if="!access"
	>У вас нет доступа в данный раздел
	</div>
	<div
		:class="$style.container"
		v-else
	>
		<NavTabs
			:data="navItems"
			active="Счета"
		/>
		<BonusCard
			:data="getBonusCard"
			@pointsOut='showPointPopup = !showPointPopup'
			id="infoTable_bills"
		/>
		<InfoTable
			:textContent="getLoyalty"
			id="infoTable_loyal"
			title="Программа лояльности"
		/>
		<InfoTable
			:column="true"
			title="История транзакций"
			id="infoTable_history"
			:textContent="getHistory"
			:headers="headerTable"
			:download="false"
			:totalPages="transactionHistoryPageCount"
			@updateList="loadHistory($event)"
		/>
		<Popup
			:visible.sync="showPointPopup"
			class="points-popup"
		>
			<RestaurantPointsOut/>
		</Popup>
	</div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
	components: {
		Map: () => import('@/components/block/Map.vue'),
		InfoTable: () => import('@/components/block/InfoTable'),
		NavTabs: () => import('@/components/block/NavTabs'),
		BonusCard: () => import('@/components/block/BonusCard'),
		RestaurantPointsOut: () => import('@/components/popup/RestaurantPointsOut.vue')
	},
	computed: {
		...mapState('personal', ['promoCode', 'promoLink', 'transactionHistory', 'transactionHistoryPageCount']),
		...mapState('restaurant', ['holdPrice', 'restPointsAmount']),
		...mapState(['user']),
		access()
		{
			return this.$store.state.user.IS_RST_OWNER || this.$store.state.user.IS_RST_MANAGER;
		},
		getLoyalty()
		{
			return [
				{
					header: 'Ваш промокод ресторана',
					body: this.promoCode
				},
				{
					header: 'Ссылка по программе лояльности',
					body: this.promoLink
				},
			]
		},
		getHistory()
		{
			let bodyTable = [];
			this.transactionHistory.forEach(el =>
			{
				let body = [
					{
						content: el.id,
						size: 'infoTable__col_small'
					},
					{
						content: el.date,
						size: 'infoTable__col_large'
					},
					{
						content: el.sum,
						size: 'infoTable__col_large'
					},
					{
						content: 'Бонусный',
						size: 'infoTable__col_middle'
					},
					{
						content: el.description,
						size: 'infoTable__col'
					},
				]
				bodyTable.push({body})
			});
			return bodyTable
		},
		getBonusCard()
		{
			return [
				{
					name: 'Бонусный счет',
					price: this.restPointsAmount,
					holdPrice: this.holdPrice + ' руб',
					color: 'bonus-card_green'
				},
			]
		}
	},
	data()
	{
		return {
			mapId: 'fullmap',
			showPointPopup: false,
			navItems: [
				{
					name: 'Счета',
					target: '#infoTable_bills'
				},
				{
					name: 'Программа лояльности',
					target: '#infoTable_loyal'
				},
				{
					name: 'История транзакций',
					target: '#infoTable_history'
				},
			],
			headerTable: [
				{
					header: 'ID',
					size: 'infoTable__col_small'
				},
				{
					header: 'Дата',
					size: 'infoTable__col_large'
				},
				{
					header: 'Сумма',
					size: 'infoTable__col_large'
				},
				{
					header: 'Счет',
					size: 'infoTable__col_middle'
				},
				{
					header: 'Описание',
					size: 'infoTable__col_grow'
				},
			],
		}
	},
	methods: {
		...mapActions('personal', ['loadLoyaltyInfo', 'loadHistory']),
		...mapActions('restaurant', ['loadRestPoints']),
		isActive(item)
		{
			return this.activeItem === item;
		},
		setActive(item)
		{
			this.activeItem = item;
		},
		scrollToTable(event)
		{
			const table = document.querySelector(event.target.dataset.target);

			if (table)
			{
				table.scrollIntoView({block: "center", behavior: 'smooth'});
			}
		},
	},
	async mounted()
	{
		if (!this.access) return;

		this.loadLoyaltyInfo();
		this.loadHistory();
		this.loadRestPoints();
	}
}

</script>

<style lang="scss">
.points-popup
{
	.popup-block
	{
		margin: auto !important;
	}
}
</style>
<style
	lang="scss"
	module
>
$gray: #979797;
$black: #3D4248;
$lightgray: #F0F0F0;
$green: #40AE49;

.container
{
	padding: 40px 72px;
}

.noAccess
{
	color: red;
	margin: 20px;
	font-size: 22px;
}

.infoTable
{
	&__col
	{
		margin-right: 0 !important;

		@media (max-width: 990px)
		{
			&_large
			{
				flex-basis: 90px !important;
				max-width: 90px !important;
			}
		}
	}
}
</style>
